import React from 'react'
import { Box } from '@material-ui/core'
import SEO from "../components/seo"
import Layout from "../components/Layout/"
import FaqLayout from '../components/Layout/FaqLayout'

const Faq = () => {
    return (
        <>
            <SEO title="FAQ" />
            <Layout withFooter withHeader>
                <Box>
                    <FaqLayout />
                </Box>
            </Layout>
        </>
    )
}

export default Faq
