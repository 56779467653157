import React, { useContext, useEffect } from 'react'
import { Container, Box, Typography } from '@material-ui/core'
import { useStyles } from './style'
import Faq from '../HomeLayout/Faq'
import loanContext from '../../../context/loan/loanContext'

const FaqLayout = () => {
    const classes = useStyles()
    const {
    faq,
    getFaqs,
  } = useContext(loanContext)

  useEffect(() => {
    faq.length < 1 && getFaqs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
        <Box id='faqpage' position='relative' paddingTop={'9rem'} paddingBottom="6.25rem" className={classes.root}>
            <Box className={classes.hero} paddingBottom="6.25rem">
                <Typography variant="h2" align="center" gutterBottom>
                    Frequently Asked Questions
                </Typography>
            </Box>
            <Box className={classes.body}>
                <Container maxWidth={'md'}>
                    <Faq 
                        title="Frequently Asked Questions"
                        QandA={faq}
                        faqPage
                    />
                </Container>
            </Box>
            <Box paddingTop="5rem">
                <Container maxWidth="md">
                    <Typography variant="h5" color="primary" gutterBottom>
                        Still Have A Question?
                    </Typography>
                    <Typography variant="h2" gutterBottom>
                        Can't Find an Answer to Your Question?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        If you still have an unanswered question or require further assistance, don’t hesitate and contact us by mail at <a href="mailto:support@bursery.com">support@bursery.com</a>. We will be sure to respond as swiftly as possible.
                    </Typography>
                </Container>
            </Box>
        </Box>
    )
}

export default FaqLayout
